
.thumbnail-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.main-image img {
  max-width: 500px;
  max-height: 500px;
  /* border: 1px solid rebeccapurple; */
  text-align: center;
}

.thumbnails {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-top: 10px;
 
}

.thumbnail {
  margin: 5px;
  cursor: pointer;
}

.thumbnail img {
  width: 60px;
  height: 60px;
  /* border: 2px solid rgb(0, 1, 68); */
}

.thumbnail.active img {
  /* border-color: #007bff; Add a border to the active thumbnail */
  width: 70px;
  height: 70px;
}

.scroll-buttons {
  margin-top: 10px;
  background-color: red;
  display: flex;
  justify-content: space-evenly;
}

button {
  margin: 5px;
  cursor: pointer;
  
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  box-shadow: inset 0 0 5px rgb(148, 148, 148); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(156, 156, 156); 
  border-radius: 10px;
}




