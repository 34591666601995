.slick-next:before {
  /* content: "/assets/icons/arrow_right.png"; */
  top: 25px;
  color: #3963ab;
}


.slick-prev:before {
  /* content: "/assets/icons/arrow_left.png"; */
  color: #3963ab;
  margin-bottom: 55px;
  /*  margin-left: -15px; */
}

.slick-slider #connectionSlick {
  text-align: center;
}
.slick-slider .connectionImage {
  margin: auto;
}
