/* TestimonialSlider.css */
.mainContainer{
  /* background-image: url("../../../public/assets/bg.jpg"); */
  background-color: "primary.main";
  padding: 40px;
}
/* .slick-next:before {
  top: 25px;
  background-color:red;
} */
/* .slick-prev:before {
  top: 25px;
  color:"primary.main";
} */
/* li.slick-active button {
  background-color: red;
} */
/* .slick-dots li button{
  background-color: red;
} */
.testimonialImg{
  width: 50%;
  aspect-ratio: 3/2;
  object-fit: contain;
  border-radius:10px;
  /* mix-blend-mode: color-burn; */
}
.testimonial-card {
    /* position: relative; */
    /* margin-left: 45px; */
    display: flex;
    
  }
  
  .testimonial-image {
    width: 300;
    height: 300;
    border-radius:3px; /* For circular images */
  }
  
  .testimonial-content {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    color: white;
    text-align: center;
    border-radius: 0 0 10px 10px;
  }
  
  .testimonial-content h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .testimonial-content p {
    margin: 5px 0;
    font-size: 14px;
  }
  